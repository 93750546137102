









import Vue from "vue";
import GcwSection from "@/components/common/GcwSection.vue";
import GcwFooter from "@/components/layout/GcwFooter.vue";
import * as ComponentsSections from "@/components/sections";
import { SECTIONS_CONTAINER_ID, SECTIONS_LIST } from "@/utils/sections";

export default Vue.extend({
  name: 'Home',
  components: {
    GcwSection,
    GcwFooter,
    ...ComponentsSections,
  },
  data() {
    return {
      sectionsList: SECTIONS_LIST,
      sectionsContainerId: SECTIONS_CONTAINER_ID,
    };
  },
  mounted(): void {
    if (this.$route.hash) {
      this.scrollIntoSection(this.$route.hash);
    }
  },
  methods: {
    scrollIntoSection(hash: string) {
      const content: HTMLElement = this.$refs.content as HTMLElement;
      const section: HTMLElement|null = content.querySelector(hash);
      if (section) {
        content.scrollTop = section.offsetTop;
      }
    },
  },
});
