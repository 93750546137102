import { isDesktop, isTablet } from "@/utils/common";

const state = {
    loading: false,
    isDesktop: isDesktop(),
    isTablet: isTablet(),
    lang: null,
};

export type AppState = typeof state;

export default state;
