

















import Vue from "vue";
import GcwIcon from "@/components/common/GcwIcon.vue";

export default Vue.extend({
    name: "gcw-accordion",
    components: {
        GcwIcon,
    },
    data () {
        return {
            accordionOpen: true,
        }
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        content: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
            default: 'arrow-down',
            required: false,
        },
        iconColor: {
            type: String,
            default: 'primary',
            required: false,
        },
        defaultOpen: {
            type: Boolean,
            default: false,
            required: false,
        },
        collapsable: {
            type: Boolean,
            default: true,
            required: false,
        },
    },
    beforeMount(): void {
        this.accordionOpen = this.defaultOpen;
    },
    methods: {
        toggleAccordion() {
            if (this.collapsable) {
                this.accordionOpen = !this.accordionOpen;
            }
        },
    },
    watch: {
        defaultOpen() {
            if (this.accordionOpen !== this.defaultOpen) {
                this.accordionOpen = this.defaultOpen;
            }
        },
        title() {
            this.accordionOpen = this.defaultOpen;
        },
        content() {
            this.accordionOpen = this.defaultOpen;
        },
    },
});
