import { ActionTree } from 'vuex';
import { State } from "@/store/types";
import { AppMutationTypes } from './mutations';
import { AppState } from './state';
import { setLocale } from "@/services/i18n";

export const AppActionTypes = {
    SET_LOADING: '@app/SET_LOADING',
    SET_IS_DESKTOP: '@app/SET_IS_DESKTOP',
    SET_IS_TABLET: '@app/SET_IS_TABLET',
    SET_LANG: '@app/SET_LANG',
};

const actions: ActionTree<AppState, State> = {
    [AppActionTypes.SET_LOADING]({ commit }, loading) {
        return new Promise((resolve) => {
            commit(AppMutationTypes.SET_LOADING, loading);
            resolve(true);
        });
    },
    [AppActionTypes.SET_IS_DESKTOP]({ commit }, isDesktop) {
        return new Promise((resolve) => {
            commit(AppMutationTypes.SET_IS_DESKTOP, isDesktop);
            resolve(true);
        });
    },
    [AppActionTypes.SET_IS_TABLET]({ commit }, isTablet) {
        return new Promise((resolve) => {
            commit(AppMutationTypes.SET_IS_TABLET, isTablet);
            resolve(true);
        });
    },
    [AppActionTypes.SET_LANG]({ commit }, lang) {
        return new Promise((resolve) => {
            commit(AppMutationTypes.SET_LANG, lang);
            setLocale(lang);
            resolve(true);
        });
    },
};

export default actions;
