





























import Vue from "vue";
import { PARTNERSHIPS_DATA, PARTNERSHIPS_DATA_TYPE } from "@/utils/partnerships";

export default Vue.extend({
    name: "gcw-sections-partnerships",
    data() {
        return {
            partnerships: PARTNERSHIPS_DATA,
        };
    },
    methods: {
        onPartnershipClick(event: Event, partnership: PARTNERSHIPS_DATA_TYPE) {
            if (!partnership.url) {
                event.preventDefault();
                event.stopPropagation();
            }
        },
    },
});
