import Vue from 'vue';
import VueI18n, { LocaleMessages } from 'vue-i18n';

Vue.use(VueI18n);

export const LANGS = {
  ES: "es",
  EN: "en",
};

function loadLocaleMessages (): LocaleMessages {
  const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages: LocaleMessages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

const messages = loadLocaleMessages();

const fallbackLocale: string = process.env.VUE_APP_I18N_FALLBACK_LOCALE || LANGS.EN;

const getLocale = (locale: string): string => {
  const localeLang = locale ? locale.split('-')[0] : fallbackLocale;

  return messages[localeLang] ? localeLang : fallbackLocale;
};

const locale: string = getLocale(navigator.language  || fallbackLocale);

const i18n =  new VueI18n({
  locale,
  fallbackLocale,
  messages,
});

export const setLocale = (locale: string): void => {
  const _locale = getLocale(locale);
  if (i18n.locale !== _locale) {
    i18n.locale = _locale;
  }
};

/**
 * Overloads VueI18n interface to avoid needing to cast return value to string.
 * @see https://github.com/kazupon/vue-i18n/issues/410
 */
declare module 'vue-i18n/types' {
  export default class VueI18n {
    t(key: Path, locale: Locale, values?: Values): string;
    t(key: Path, values?: Values): string;
  }
}

export default i18n;


