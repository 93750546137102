export type TEAM_DATA_TYPE = {
    name: string;
    position: string;
    image: string;
    linkedin?: {
        name: string;
        url: string;
    };
};

export const TEAM_DATA_LIST: TEAM_DATA_TYPE[] = [
    {
        name: 'Álvaro Remiro',
        position: 'CEO - Founder',
        image: require('@/assets/img/team/alvaro.png'),
        linkedin: {
            name: 'Álvaro Remiro',
            url: 'https://www.linkedin.com/in/%C3%A1lvaro-remiro-chac%C3%B3n-a54b8382/',
        },
    },
    {
        name: 'Abel Chocano',
        position: 'CTO - Founder',
        image: require('@/assets/img/team/abel.png'),
        linkedin: {
            name: 'Abel Chocano',
            url: 'https://www.linkedin.com/in/abel-chocano-gomez/',
        },
    },
    {
        name: 'Nicolás Baños',
        position: 'Developer',
        image: require('@/assets/img/team/nico.png'),
    },
    {
        name: 'Martín Sánchez',
        position: 'Developer',
        image: require('@/assets/img/team/martin.png'),
    },
    {
        name: 'Andrea Cuadrado',
        position: 'UX/UI Designer',
        image: require('@/assets/img/team/andrea.png'),
    },
    {
        name: 'Andrea Rubio',
        position: 'UX/UI Designer & Art Leader',
        image: require('@/assets/img/team/andi.png'),
    },
    {
        name: 'Jairo Colmenero',
        position: 'eSports Manager',
        image: require('@/assets/img/team/astures.png'),
    },
    {
        name: 'Álvaro González',
        position: 'Tournament HUB Manager',
        image: require('@/assets/img/team/larru.png'),
    },
    {
        name: 'Sergio Rocillo',
        position: 'Customer Experience',
        image: require('@/assets/img/team/roci.png'),
    },
    {
        name: 'Alberto Flores',
        position: 'Community Manager',
        image: require('@/assets/img/team/alberto.png'),
    },
    {
        name: 'Félix Martínez',
        position: 'Video Editor',
        image: require('@/assets/img/team/felix.png'),
    },
    {
        name: 'Roger Ytarte',
        position: 'Graphic Designer',
        image: require('@/assets/img/team/roger.png'),
    },
];
