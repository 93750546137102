export type ROADMAP_DATA_TYPE = {
    titleLabel: string;
    textLabel: string;
    image: string;
    color: string;
    className: string;
};

export const ROADMAP_DATA: ROADMAP_DATA_TYPE[] = [
    {
        titleLabel: '26',
        textLabel: '31',
        image: require('@/assets/img/roadmap/development.svg'),
        color: '#7202ED',
        className: 'development-step',
    },
    {
        titleLabel: '27',
        textLabel: '32',
        image: require('@/assets/img/roadmap/concept.svg'),
        color: '#41B4E6',
        className: 'concept-step',
    },
    {
        titleLabel: '28',
        textLabel: '33',
        image: require('@/assets/img/roadmap/results.svg'),
        color: '#DB5077',
        className: 'results-step',
    },
    {
        titleLabel: '29',
        textLabel: '34',
        image: require('@/assets/img/roadmap/budget.svg'),
        color: '#D84FFD',
        className: 'budget-step',
    },
    {
        titleLabel: '30',
        textLabel: '35',
        image: require('@/assets/img/roadmap/announce.svg'),
        color: '#5044FF',
        className: 'announce-step',
    },
];
