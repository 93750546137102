





























































import Vue from "vue";

export default Vue.extend({
    name: "gcw-games-scheme",
});
