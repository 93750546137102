









































import Vue, { PropType } from "vue";
import GcwIcon from "@/components/common/GcwIcon.vue";
import { TEAM_DATA_TYPE } from "@/utils/team";
import { BCarousel } from "bootstrap-vue";

export default Vue.extend({
    name: "gcw-sections-team-carousel",
    components: {
        GcwIcon,
    },
    props: {
        itemsPerSlide: {
            type: Number,
            required: true,
        },
        teamList: {
            type: Array as PropType<TEAM_DATA_TYPE[][]>,
            required: true,
        },
        numSlides: {
            type: Number,
            required: true,
        },
        showNextSlide: {
            type: Boolean,
            required: true,
        },
        onSlideChange: {
            type: Function as PropType<(slide: number) => void>,
            required: false,
            default: () => {/** Nothing to do */},
        },
    },
    methods: {
        prev() {
            const carousel = this.$refs.carousel as BCarousel;
            carousel.prev();
        },
        next() {
            const carousel = this.$refs.carousel as BCarousel;
            carousel.next();
        },
        setSlide(slide: number) {
            const carousel = this.$refs.carousel as BCarousel;
            carousel.setSlide(slide);
        },
        getNextSlide(index: number): TEAM_DATA_TYPE {
            return (this.numSlides > (index + 1))
                ? this.teamList[index + 1][0]
                : this.teamList[0][0];
        },
    },
});
