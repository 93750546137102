























































































import Vue from "vue";
import GcwIcon from "@/components/common/GcwIcon.vue";
import GcwGamesScheme from "@/components/sections/GcwSectionsPlatform/GcwGamesScheme.vue";

export default Vue.extend({
    name: "gcw-sections-game-gcoin-gfy",
    components: {
        GcwIcon,
        GcwGamesScheme,
    },
});
