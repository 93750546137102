export type PARTNERSHIPS_DATA_TYPE = {
    name: string;
    image: {
        src: string;
        portrait?: boolean;
    };
    url?: string;
};

export const PARTNERSHIPS_DATA: PARTNERSHIPS_DATA_TYPE[] = [
    {
        name: 'Google for Startups',
        url: 'https://startup.google.com/',
        image: {
            src: require('@/assets/img/partners/google-for-startups.png'),
        },
    },
    {
        name: 'SeedRocket',
        url: 'https://www.seedrocket.com/',
        image: {
            src: require('@/assets/img/partners/seedrocket.png'),
        },
    },
    {
        name: 'Lanzadera',
        url: 'https://lanzadera.es/',
        image: {
            src: require('@/assets/img/partners/lanzadera.png'),
        },
    },
    {
        name: 'ICEX',
        url: 'https://www.icex.es/icex/es/index.html',
        image: {
            src: require('@/assets/img/partners/icex.png'),
        },
    },
    {
        name: 'Cámara Oficial Comercio',
        url: 'https://www.camaramadrid.es/',
        image: {
            src: require('@/assets/img/partners/camara.png'),
        },
    },
    {
        name: 'Satoshis Games',
        url: 'https://satoshis.games/',
        image: {
            src: require('@/assets/img/partners/satoshis.png'),
        },
    },
    {
        name: 'Dreamhack',
        url: 'https://dreamhack.es/',
        image: {
            src: require('@/assets/img/partners/dreamhack.png'),
        },
    },
    {
        name: 'Discord',
        url: 'https://discord.com/',
        image: {
            src: require('@/assets/img/partners/discord.png'),
        },
    },
    {
        name: 'Supercell',
        url: 'https://supercell.com/',
        image: {
            src: require('@/assets/img/partners/supercell.png'),
        },
    },
    {
        name: 'Eneba',
        url: 'https://www.eneba.com',
        image: {
            src: require('@/assets/img/partners/eneba.png'),
        },
    },
    {
        name: 'Legion',
        url: 'https://www.lenovo.com/es/es/legion',
        image: {
            src: require('@/assets/img/partners/legion.png'),
        },
    },
    {
        name: 'Gamepolis',
        url: 'https://gamepolis.org/',
        image: {
            src: require('@/assets/img/partners/gamepolis.png'),
        },
    },
];
