













































































import Vue from "vue";
import GcwIcon from "@/components/common/GcwIcon.vue";

export default Vue.extend({
    name: "gcw-sections-game-future",
    components: {
        GcwIcon,
    },
});
