export type TOKENOMICS_DATA_TYPE = {
    labelId: string;
    icon: string;
    percent: number;
};

export const TOKENOMICS_DATA_LIST: TOKENOMICS_DATA_TYPE[] = [
    {
        labelId: '37',
        icon: 'dartboard',
        percent: 10
    },
    {
        labelId: '38',
        icon: 'megaphone',
        percent: 14
    },
    {
        labelId: '39',
        icon: 'money',
        percent: 5
    },
    {
        labelId: '40',
        icon: 'team',
        percent: 21
    },
];
