


































import Vue from "vue";
import GcwAccordion from "@/components/common/GcwAccordion.vue";
import GcwIcon from "@/components/common/GcwIcon.vue";
import { FAQS_GROUP_DATA_TYPE, FAQS_DATA_TYPE, FAQS_DATA } from "@/utils/faqs";

export default Vue.extend({
    name: "gcw-sections-faqs",
    components: {
        GcwAccordion,
        GcwIcon,
    },
    data() {
        return {
            groupIndexSelected: 0,
        };
    },
    computed: {
        faqsData(): FAQS_GROUP_DATA_TYPE[] {
            return FAQS_DATA;
        },
        hasGroups(): boolean {
            return this.faqsData.length > 1;
        },
        faqsList(): FAQS_DATA_TYPE[] {
            return this.faqsData[this.groupIndexSelected].faqs;
        },
    },
    methods: {
        onGroupClick(index: number) {
            this.groupIndexSelected = index;
        },
    },
});
