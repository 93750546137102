export const isDesktop = (): boolean => {
    return window.innerWidth > 992;
};

export const isTablet = (): boolean => {
    const width = window.innerWidth;
    return width <= 992 && width >= 768;
};

export function debounce<T extends (...args: any[]) => void>(cb: T, wait = 200): T {
    let h = 0;
    const callable = (...args: any) => {
        clearTimeout(h);
        h = setTimeout(() => cb(...args), wait);
    };
    return <T>(<any>callable);
}
