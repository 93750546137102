
































































import Vue from "vue";
import GcwIcon from "@/components/common/GcwIcon.vue";
import GcwBuyTokenButton from "@/components/common/GcwBuyTokenButton.vue";

export default Vue.extend({
    name: "gcw-sections-home",
    components: {
        GcwIcon,
        GcwBuyTokenButton,
    },
    methods: {
        onAppStoreClick() {
            window.open('https://apps.apple.com/es/app/gamersfy/id1346243500', '_blank');
        },
        onPlayStoreClick() {
            window.open('https://play.google.com/store/apps/details?id=com.gamerrevolution.gamersfy_app&hl=es_419', '_blank');
        },
        onDiscordClick() {
            window.open('https://discord.gg/zsWb8KNmca', '_blank');
        }
    },
});
