import Vue from 'vue';
import Vuex from 'vuex';
import { State } from './types';
import * as modules from './modules';
import VuexPersistence from 'vuex-persist';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  // Part of the state to persist
  reducer: (state: State) => ({
    app: {
      lang: state.app.lang,
    },
  }),
});

export const store = new Vuex.Store<State>({
  modules,
  plugins: [
    vuexLocal.plugin,
  ],
});
