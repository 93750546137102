export const SECTIONS_CONTAINER_ID = 'home-container';

export const SECTIONS_IDS = {
    HOME: 'home',
    VIDEO: 'video',
    GAME: 'platform',
    TOKENOMICS: 'tokenomics',
    TEAM: 'team',
    ROADMAP: 'roadmap',
    PARTNERSHIPS: 'partnerships',
    FAQS: 'faqs',
};

export const SECTIONS_LABELS = {
    [SECTIONS_IDS.HOME]: '1',
    [SECTIONS_IDS.GAME]: '2',
    [SECTIONS_IDS.VIDEO]: '43',
    [SECTIONS_IDS.TEAM]: '3',
    [SECTIONS_IDS.ROADMAP]: '4',
    [SECTIONS_IDS.TOKENOMICS]: '5',
    [SECTIONS_IDS.PARTNERSHIPS]: '8',
    [SECTIONS_IDS.FAQS]: '6',
};

export const SECTIONS_LIST = [
    SECTIONS_IDS.HOME,
    SECTIONS_IDS.VIDEO,
    SECTIONS_IDS.GAME,
    SECTIONS_IDS.TOKENOMICS,
    SECTIONS_IDS.TEAM,
    SECTIONS_IDS.ROADMAP,
    SECTIONS_IDS.PARTNERSHIPS,
    SECTIONS_IDS.FAQS,
];