import Vue from 'vue';
import App from './App.vue';
import router from './router';
import { store }  from './store';
import i18n from './services/i18n'
import '@/services/bootstrap';
import '@/mixins/global';
import '@/assets/css/app.scss';
import { AppActionTypes } from "@/store/modules/app/actions";

Vue.config.productionTip = false;

const { lang } = store.state.app;
const userLang = lang ? lang : i18n.locale;

store.dispatch(AppActionTypes.SET_LANG, userLang);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');
