




import Vue from 'vue';
const icons: any = {};

const iconComponentName = (name: string) => `gcw-${name}-icon`;
const requireComponents = require.context('../../assets/img/icons/', false, /[\w]\.svg$/);

requireComponents.keys().forEach(fileName => {
    const iconName = fileName.replace(/^\.\/(.+)\.svg/, '$1');
    icons[iconComponentName(iconName)] = () => import(`@/assets/img/icons/${iconName}.svg?inline`);
});

export default Vue.extend({
    name: "gcw-icon",
    props: {
        name: {
            type: String,
            required: true,
        },
    },
    components: {...icons},
    computed: {
        iconComponent() {
            return icons[iconComponentName(this.name)]
        }
    },
    inheritAttrs: false,
});
