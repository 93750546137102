





























import Vue from "vue";
import GcwIcon from "@/components/common/GcwIcon.vue";
import GcwSectionsTeamCarousel from "@/components/sections/GcwSectionsTeam/GcwSectionsTeamCarousel.vue";
import { TEAM_DATA_LIST, TEAM_DATA_TYPE} from "@/utils/team";

export default Vue.extend({
    name: "gcw-sections-team",
    components: {
        GcwIcon,
        GcwSectionsTeamCarousel,
    },
    data() {
        return {
            slide: 0,
        };
    },
    computed: {
        itemsPerSlide(): number {
            return !this.isMobile ? 3 : 1;
        },
        teamList(): TEAM_DATA_TYPE[][] {
            const teamList: TEAM_DATA_TYPE[][] = [];
            const totalItems = TEAM_DATA_LIST.length;

            for (let i = 0; i < totalItems; i += this.itemsPerSlide) {
                teamList.push(TEAM_DATA_LIST.slice(i, i + this.itemsPerSlide));
            }

            return teamList;
        },
        numSlides(): number {
            return this.teamList.length;
        },
        showNextSlide(): boolean {
            return this.isMobile;
        },
    },
    methods: {
        prevSlide() {
            const carousel = this.$refs.carousel as any;
            carousel.prev();
        },
        nextSlide() {
            const carousel = this.$refs.carousel as any;
            carousel.next();
        },
        onSlideChange(slide: number) {
            this.slide = slide;
        },
    },
    watch: {
        isDesktop() {
            const carousel = this.$refs.carousel as any;
            carousel.setSlide(0);
        }
    },
});
