import { render, staticRenderFns } from "./GcwSectionsTeam.vue?vue&type=template&id=094c24d3&scoped=true&"
import script from "./GcwSectionsTeam.vue?vue&type=script&lang=ts&"
export * from "./GcwSectionsTeam.vue?vue&type=script&lang=ts&"
import style0 from "./GcwSectionsTeam.vue?vue&type=style&index=0&id=094c24d3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "094c24d3",
  null
  
)

export default component.exports