


































































import Vue from "vue";
import GcwIcon from "@/components/common/GcwIcon.vue";
import GcwBuyTokenButton from "@/components/common/GcwBuyTokenButton.vue";
import { SECTIONS_CONTAINER_ID, SECTIONS_LABELS, SECTIONS_LIST } from "@/utils/sections";
import { AppActionTypes } from "@/store/modules/app/actions";
import { LANGS } from "@/services/i18n";

export default Vue.extend({
    name: "gcw-header",
    components: {
        GcwIcon,
        GcwBuyTokenButton,
    },
    data() {
        return {
            sectionsList: SECTIONS_LIST,
            sectionsLabels: SECTIONS_LABELS,
            sectionsContainerId: SECTIONS_CONTAINER_ID,
            langs: LANGS,
        };
    },
    methods: {
        onLanguageClick(lang: string) {
            this.$store.dispatch(AppActionTypes.SET_LANG, lang);
        },
    },
});
