








import Vue from 'vue';
import GcwHeader from "@/components/layout/GcwHeader.vue";
import { AppActionTypes } from "@/store/modules/app/actions";
import { isDesktop, debounce, isTablet } from "@/utils/common";

export default Vue.extend({
  name: 'App',
  components: {
    GcwHeader,
    GcwSidebarMobile: () => import("@/components/layout/GcwSidebarMobile.vue"),
  },
  mounted(): void {
    window.addEventListener('resize', debounce(() => {
      this.$store.dispatch(AppActionTypes.SET_IS_DESKTOP, isDesktop());
      this.$store.dispatch(AppActionTypes.SET_IS_TABLET, isTablet());
    }));
  },
});
