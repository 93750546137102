












































import Vue from "vue";
import { ROADMAP_DATA } from "@/utils/roadmap";

export default Vue.extend({
    name: "gcw-sections-roadmap",
    data() {
        return {
            roadmapList: ROADMAP_DATA,
        };
    },
    computed: {
        numSteps(): number {
            return this.roadmapList.length;
        }
    },
});
