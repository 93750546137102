import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';
import Terms from '../views/Terms.vue';
import Policy from '../views/Policy.vue';
import { ROUTES } from "./routes";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    ...ROUTES.home,
    component: Home,
  },
  {
    ...ROUTES.terms,
    component: Terms,
  },
  {
    ...ROUTES.policy,
    component: Policy,
  },
  {
    path: '*',
    redirect: ROUTES.home.path,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth'
      }
    } else {
      return { x: 0, y: 0 }
    }
  }
});

export default router;
