




import Vue from "vue";

export default Vue.extend({
    name: "gcw-buy-token-button",
    data() {
        return {
            buttonClicked: false,
            buttonHover: false,
        };
    },
    computed: {
        comingSoonMessage(): boolean {
            return this.buttonClicked || this.buttonHover;
        }
    },
    methods: {
        onBuyTokenClick() {
            this.buttonClicked = true;
            setTimeout(() => {
                this.$nextTick(() => {
                    this.buttonClicked = false;
                });
            }, 4000);
        },
        onBuyTokenOver() {
            this.buttonHover = true;
        },
        onBuyTokenOut() {
            this.buttonHover = false;
        },
    },
});
