import { MutationTree } from 'vuex/types';
import { AppState } from './state';

export const AppMutationTypes = {
    SET_LOADING: '@app/SET_LOADING',
    SET_IS_DESKTOP: '@app/SET_IS_DESKTOP',
    SET_IS_TABLET: '@app/SET_IS_TABLET',
    SET_LANG: '@app/SET_LANG',
};

const mutations: MutationTree<AppState> = {
    [AppMutationTypes.SET_LOADING](app: AppState, loading: boolean) {
        app.loading = loading;
    },
    [AppMutationTypes.SET_IS_DESKTOP](app: AppState, isDesktop) {
        app.isDesktop = isDesktop;
    },
    [AppMutationTypes.SET_IS_TABLET](app: AppState, isTablet) {
        app.isTablet = isTablet;
    },
    [AppMutationTypes.SET_LANG](app: AppState, lang) {
        app.lang = lang;
    },
};

export default mutations;
