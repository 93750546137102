











import Vue from "vue";
import GcwSectionsGameNow from "@/components/sections/GcwSectionsPlatform/GcwSectionsGameNow.vue";
import GcwSectionsGameFuture from "@/components/sections/GcwSectionsPlatform/GcwSectionsGameFuture.vue";
import GcwSectionsGameGcoinGfy from "@/components/sections/GcwSectionsPlatform/GcwSectionsGameGcoinGfy.vue";

export default Vue.extend({
    name: "gcw-sections-platform",
    components: {
        GcwSectionsGameNow,
        GcwSectionsGameFuture,
        GcwSectionsGameGcoinGfy,
    },
});
