export type FAQS_DATA_TYPE = {questionLabel: string, answerLabel: string};

export type FAQS_GROUP_DATA_TYPE = {
    groupLabel?: string;
    faqs: FAQS_DATA_TYPE[];
};

export const FAQS_DATA: FAQS_GROUP_DATA_TYPE[] = [
    {
        groupLabel: '21',
        faqs: [
            {questionLabel: '84', answerLabel: '85'},
            {questionLabel: '86', answerLabel: '87'},
            {questionLabel: '88', answerLabel: '89'},
            {questionLabel: '90', answerLabel: '91'},
        ],
    },

    {
        groupLabel: '22',
        faqs: [
            {questionLabel: '92', answerLabel: '93'},
            {questionLabel: '94', answerLabel: '95'},
            {questionLabel: '96', answerLabel: '97'},
            {questionLabel: '98', answerLabel: '99'},
        ],
    },

    {
        groupLabel: '23',
        faqs: [
            {questionLabel: '100', answerLabel: '101'},
            {questionLabel: '102', answerLabel: '103'},
            {questionLabel: '104', answerLabel: '105'},
        ],
    },
];
