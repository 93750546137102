











































import Vue from "vue";
import { ROUTES } from "@/router/routes";
import GcwIcon from "@/components/common/GcwIcon.vue";

export default Vue.extend({
    name: "gcw-footer",
    components: {
        GcwIcon
    },
    data() {
        return {
            routes: ROUTES,
        }
    },
    methods: {
        onClickContactUs(){
            window.open('mailto:info@gamersfy.com?subject=Gamersfy Crypto and NFT');
        }
    },
});
