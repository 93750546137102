
























































import Vue from "vue";
import GcwIcon from "@/components/common/GcwIcon.vue";
import GcwBuyTokenButton from "@/components/common/GcwBuyTokenButton.vue";
import { TOKENOMICS_DATA_LIST } from "@/utils/tokenomics";

export default Vue.extend({
    name: "gcw-sections-tokenomics",
    components: {
        GcwIcon,
        GcwBuyTokenButton,
    },
    data() {
        return {
            tokenomicsList: TOKENOMICS_DATA_LIST,
        };
    },
});
