









import Vue from "vue";
import GcwSection from "@/components/common/GcwSection.vue";
import GcwSectionsPolicy from "@/components/sections/GcwSectionsPolicy.vue";
import GcwFooter from "@/components/layout/GcwFooter.vue";

export default Vue.extend({
  name: 'Policy',
  components: {
    GcwSection,
    GcwSectionsPolicy,
    GcwFooter
  },
});
