import { render, staticRenderFns } from "./GcwSectionsGameNow.vue?vue&type=template&id=1845ecae&scoped=true&"
import script from "./GcwSectionsGameNow.vue?vue&type=script&lang=ts&"
export * from "./GcwSectionsGameNow.vue?vue&type=script&lang=ts&"
import style0 from "./GcwSectionsGameNow.vue?vue&type=style&index=0&id=1845ecae&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1845ecae",
  null
  
)

export default component.exports