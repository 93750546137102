









import Vue from "vue";
import GcwSection from "@/components/common/GcwSection.vue";
import GcwSectionsTerms from "@/components/sections/GcwSectionsTerms.vue";
import GcwFooter from "@/components/layout/GcwFooter.vue";

export default Vue.extend({
  name: 'Terms',
  components: {
    GcwSection,
    GcwSectionsTerms,
    GcwFooter
  },
});
