import Vue from 'vue';
import { AppActionTypes } from "@/store/modules/app/actions";
import { SECTIONS_CONTAINER_ID } from "@/utils/sections";

Vue.mixin(Vue.extend({
    computed: {
        isDesktop() {
            return this.$store.state.app.isDesktop;
        },
        isTablet() {
            return this.$store.state.app.isTablet;
        },
        isMobile() {
            return !this.isDesktop && !this.isTablet;
        },
        currentLang() {
            return this.$i18n.locale;
        },
    },
    methods: {
        async showLoader(show: boolean) {
            await this.$store.dispatch(AppActionTypes.SET_LOADING, show);
        },
        scrollIntoView(hash: string) {
            const content: HTMLElement|null = document.querySelector(`#${SECTIONS_CONTAINER_ID}`);
            const element: HTMLElement|null = hash && content ? content.querySelector(hash) : null;
            if (element && content) {
                content.scrollTop = element.offsetTop;
            }
        },
        onBuyTokenClick() {
            // TODO
        },
    },
}));

declare module 'vue/types/vue' {
    interface Vue {
        isDesktop: boolean;
        isTablet: boolean;
        isMobile: boolean;
        currentLang: string;
        showLoader: (show: boolean) => Promise<void>;
        scrollIntoView: (hash: string) => void;
        onBuyTokenClick: () => void;
    }
}
