






import Vue from "vue";

export default Vue.extend({
    name: "gcw-section",
    created(): void {
        this.$root.$on('bv::scrollspy::activate', (target: string) => {
            this.activated = target === `#${this.sectionId}`;
        });
    },
    data() {
        return {
            activated: false,
        };
    },
    props: {
        sectionId: {
            type: String,
            required: true,
        },
    },
});
